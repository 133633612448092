import React from "react";
import { IScanSummary } from "../scan.types";
import Chip from "../../designSystem/chip/chip";
import { FINDING_STATUS } from "../../finding/finding.types";
import { SHAPES } from "../../designSystem/chip/chip.constants";
import SkeletonLoader from "../../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../../designSystem/skeleton/skeleton.constants";

type SummaryProps = {
  summary: IScanSummary;
  isLoading: boolean;
  orgName: string;
  loadingOrganizations: boolean;
};

export default function Summary({
  orgName,
  summary: { findings, inspectors, severity, status },
  isLoading,
  loadingOrganizations
}: SummaryProps) {
  return (
    <section className="scan__summary">
      <div className="scan__summary__header">
        <h5>Summary</h5>
        <SkeletonLoader loadingVar={loadingOrganizations}>
          <h6>{orgName}</h6>
        </SkeletonLoader>
      </div>
      <div className="scan__totals">
        <div className="scan__inspectors">
          <label className="label label--bold">Total inspectors</label>
          <SkeletonLoader loadingVar={isLoading}>
            <h2>{inspectors}</h2>
          </SkeletonLoader>
        </div>
        <div className="scan__count">
          <label className="label label--bold">Total findings</label>
          <SkeletonLoader loadingVar={isLoading}>
            <h2>{findings}</h2>
          </SkeletonLoader>
        </div>
      </div>
      <div className="scan__severity">
        <label className="label label--bold">Findings severity</label>
        <div className="scan__severity__totals">
          <div className="scan__severity__critical">
            <label>Critical</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.critical}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--critical" />
          </div>
          <div className="scan__severity__high">
            <label>High</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.high}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--high" />
          </div>
          <div className="scan__severity__medium">
            <label>Medium</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.medium}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--medium" />
          </div>
          <div className="scan__severity__low">
            <label>Low</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.low}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--low" />
          </div>
          <div className="scan__severity__informational">
            <label>Informational</label>
            <SkeletonLoader loadingVar={isLoading}>
              <h3>{severity?.informational}</h3>
            </SkeletonLoader>
            <div className="scan__severity__bar scan__severity__bar--informational" />
          </div>
        </div>
      </div>
      <div className="scan__status">
        <label className="label label--bold">Status</label>
        <div className="scan__status__totals">
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.new} | New</label>}
              status={FINDING_STATUS.new}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.unchanged} | Unchanged</label>}
              status={FINDING_STATUS.unchanged}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.modified} | Modified</label>}
              status={FINDING_STATUS.modified}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.chip}
          >
            <Chip
              label={<label>{status?.remediated} | Remediated</label>}
              status={FINDING_STATUS.remediated}
              shape={SHAPES.OVAL}
            />
          </SkeletonLoader>
        </div>
      </div>
    </section>
  );
}
