import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import "./findingDetails.scss";
import Chip from "../../../designSystem/chip/chip";
import { SHAPES } from "../../../designSystem/chip/chip.constants";
import Icon from "../../../designSystem/icon/icon";
import useFindingDetailsViewModel from "./findingDetails.view.model";
import {
  Frameworks,
  AffectedObject,
  FINDING_EVENTS,
  FindingDetailsUI,
  IInspector,
  AcceptancePeriodValuesExtends
} from "../../finding.types";
import { Tabs, Tab } from "../../../designSystem/tabs/tabs";
import { DOWNLOAD, RESUME, PAUSED } from "../../../constants/images";
import { IAnyPropertyNameAndAnyValue } from "../../../constants/common.types";
import { InspectorsTableContext } from "../../finding";

import FINDING_CONSTANTS from "../../finding.constants";
import featureFlagsContext from "../../../contexts/feature-flags.provider";

type FindingDetailsProps = {
  details: FindingDetailsUI;
  affectedObjects: AffectedObject[];
  inspector: IInspector | null;
  trackingStatus?: string;
};

function FrameworkMaping({
  frameworks
}: {
  frameworks: IAnyPropertyNameAndAnyValue;
}) {
  return (
    <div className="findingDetails__property">
      <h5>Frameworks</h5>
      {Object.keys(frameworks)?.length > 0 ? (
        <div>
          <div className="findingDetails__references">
            {Object.keys(frameworks).map((key: any, objectIndex) => {
              const { name: mainName, version: mainVersion } = frameworks[key];
              return (
                <div className="findingDetails__frameworks" key={objectIndex}>
                  <p className="findingDetails__frameworks_version">
                    {mainName}
                    <span className="findingDetails__frameworks_version_number">
                      {" - "}
                      {mainVersion}
                    </span>
                  </p>
                  {frameworks[key].data?.map(
                    (framework: Frameworks, index: any) => {
                      const {
                        control,
                        controlName,
                        controlDescription,
                        attributes
                      } = framework;
                      return (
                        <div key={index}>
                          <p className="findingDetails__frameworks_name">
                            {control} - {controlName}
                          </p>
                          <p className="findingDetails__frameworks_description">
                            {controlDescription}
                          </p>
                          {attributes?.licenseLevel.label && (
                            <div className="findingDetails__frameworks_attributes">
                              <span className="findingDetails__frameworks_attributes_label">
                                {`${attributes.licenseLevel.label}: `}
                              </span>
                              {attributes.licenseLevel.value}
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          </div>
          <div />
          <div />
        </div>
      ) : (
        <div>
          <p>
            This finding is not associated with any framework recommendations.
          </p>
        </div>
      )}
    </div>
  );
}

function AcceptFinding(props: any) {
  const { trackingStatus, details } = props;
  const { onAcceptFinding, onResumeFinding } = useContext(
    InspectorsTableContext
  );
  const handleClick = useCallback(
    (inspectorId: string, findingName: string) => {
      if (trackingStatus === AcceptancePeriodValuesExtends.None) {
        onAcceptFinding &&
          onAcceptFinding(inspectorId || "", findingName || "");
      } else {
        onResumeFinding &&
          onResumeFinding(inspectorId || "", findingName || "", trackingStatus);
      }
    },
    [onAcceptFinding, onResumeFinding, trackingStatus]
  );
  const buttonText = useMemo(
    () =>
      trackingStatus === AcceptancePeriodValuesExtends.None
        ? FINDING_CONSTANTS["Accept & Remove from Scans"]
        : FINDING_CONSTANTS["Resume"],
    [trackingStatus]
  );
  const icon = useMemo(
    () =>
      trackingStatus === AcceptancePeriodValuesExtends.None ? PAUSED : RESUME,
    [trackingStatus]
  );
  return (
    <div
      className="accept-findings-button-holder has-tooltip"
      onClick={() => {
        handleClick(details?.inspectorID, details?.findingName);
      }}
    >
      <span className="accept-findings-button">
        <Icon image={icon} />{" "}
      </span>
      <span className="accept-findings-button-text">{buttonText}</span>
    </div>
  );
}

export default function FindingDetails(props: FindingDetailsProps) {
  const { details, affectedObjects, inspector } = props;
  const { description, remediation, references, status, frameworks } = details;
  const { affectedList, affectedSummary, handleDownloadAffectedObjects } =
    useFindingDetailsViewModel(affectedObjects, status);
  const { inspectAcceptedRisk } = useContext(featureFlagsContext);

  const [activeTab, setActiveTab] = useState("tab-0");
  const summaryKeys = Object.keys(affectedSummary);

  const onStatusSelected = () => setActiveTab("tab-1");

  useEffect(() => {
    window.addEventListener(FINDING_EVENTS.statusSelected, onStatusSelected);
    return () =>
      window.removeEventListener(
        FINDING_EVENTS.statusSelected,
        onStatusSelected
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="findingDetails">
      {inspectAcceptedRisk && (
        <AcceptFinding
          trackingStatus={
            details?.trackingStatus || AcceptancePeriodValuesExtends.None
          }
          inspector={inspector}
          details={details}
        />
      )}
      <Tabs currentTab={activeTab}>
        <Tab title="Details">
          <div className="findingDetails__property">
            <div className="inspector-title ">
              <h5>Inspector</h5>
            </div>
            <p>{inspector?.findingName}</p>
          </div>
          <div className="findingDetails__property">
            <h5>Description</h5>
            <p>{description}</p>
          </div>
          <div className="findingDetails__property">
            <h5>Remediation</h5>
            <p>{remediation}</p>
          </div>
          <div className="findingDetails__property">
            <h5>References</h5>
            <div className="findingDetails__references">
              {references.map((reference, index) => (
                <a
                  key={index}
                  href={reference.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {reference.text}
                </a>
              ))}
            </div>
          </div>
          <FrameworkMaping frameworks={frameworks} />
        </Tab>
        <Tab title="Affected">
          <div className="findingDetails__affected">
            <div className="findingDetails__affected__summary">
              <div className="findingDetails__affected__status">
                {summaryKeys.map((subStatus, index) => (
                  <Chip
                    key={index}
                    label={`${affectedSummary[subStatus]} | ${subStatus}`}
                    status={subStatus.toLowerCase()}
                    shape={SHAPES.OVAL}
                  />
                ))}
              </div>
              <Icon
                image={DOWNLOAD}
                alt="Download"
                onClick={handleDownloadAffectedObjects}
              />
            </div>
            <div className="findingDetails__affected__list">
              {affectedList.map((object, index) => {
                // eslint-disable-next-line no-shadow
                const { name, status } = object;

                return (
                  <div key={index} className="findingDetails__affected__item">
                    <p>{name}</p>
                    {status && (
                      <Chip
                        label={status}
                        status={status.toLowerCase()}
                        shape={SHAPES.OVAL}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
