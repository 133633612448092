/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo, useState } from "react";
import "../finding.scss";

import { Field } from "formik";
import ModalContext from "../../contexts/modal.context";
import Select from "../../designSystem/select/select";
import TextArea from "../../designSystem/textArea/textArea";
import { MAX_CHARS_NOTES } from "../../constants/utils";
import {
  AcceptancePeriodKey,
  AcceptancePeriodOption,
  AcceptancePeriodValues,
  AcceptancePeriodValuesExtends
} from "../finding.types";

function acceptancePeriodIntervalsMapper(): AcceptancePeriodOption[] {
  return Object.entries(AcceptancePeriodValues).map((value) => ({
    value: value[0] as AcceptancePeriodKey,
    id: value[0] as AcceptancePeriodKey
  }));
}

export function ResumeFindingForm({
  formik: { handleSubmit },
  findingName
}: any) {
  return (
    <form
      id="resumeFindingForm"
      className="finding-management finding-management-resume"
      onSubmit={handleSubmit}
    >
      <div className="finding-management__information">
        <p className="finding-management__information-text">
          Are you sure you want to resume{" "}
          <span className="label--bolder">{findingName}</span>
        </p>
        <br />
        <p className="finding-management__information-subtitle">
          This setting will only affect future scans.
        </p>
      </div>
    </form>
  );
}

export default function AcceptFindingsForm({
  formik: { handleSubmit, values, errors, setFieldValue, isValid },
  findingName
}: any) {
  const { setActionDisabled } = useContext(ModalContext);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    setActionDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);
  // console.log({ values });
  const riskValue = useMemo(() => {
    const { acceptedRisk } = values;
    if (acceptedRisk === AcceptancePeriodValuesExtends.None) {
      return "";
    }
    return acceptedRisk;
  }, [values]);

  return (
    <form
      id="acceptFindingsForm"
      className="accept-finding"
      onSubmit={handleSubmit}
    >
      <div className="accept-finding__information">
        <p className="accept-finding__information-text">
          Accepting the finding{" "}
          <span className="label--bolder">{findingName}</span> will filter it
          from your scan results.
        </p>
      </div>
      <div className="accept-finding__period">
        <label className="label label--bold" htmlFor="acceptedRisk">
          Acceptance period
        </label>
        <Field
          name="acceptedRisk"
          value={riskValue}
          onChange={(value: string) => {
            setFieldValue("acceptedRisk", value);
          }}
          items={acceptancePeriodIntervalsMapper()}
          component={Select}
          placeholder="Select"
        />
      </div>

      <div className="accept-finding__notes">
        <div className="accept-finding__notes-label">
          <label className="label label--bold">Notes</label>
        </div>
        <Field
          name="notes"
          value={values.notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setFieldValue("notes", value);
            setCharacterCount(value.length);
          }}
          isValid={!errors?.notes}
          width="Max"
          maxLength={MAX_CHARS_NOTES}
          component={TextArea}
        />
        {errors?.notes ? (
          <span>{errors.notes}</span>
        ) : (
          <span>
            {characterCount}/{MAX_CHARS_NOTES}
          </span>
        )}
      </div>
    </form>
  );
}
