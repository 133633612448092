import React, { createContext, useMemo } from "react";
import "./finding.scss";
import Header from "../designSystem/header/header";
import FindingSummary from "./components/findingSummary/findingSummary";
import FindingDetails from "./components/findingDetails/FindingDetails";
import useFindingViewModel from "./finding.view.model";
import { LOADING } from "../constants/images";
import { acceptancePeriodList } from "./finding.types";
import SkeletonLoader from "../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../designSystem/skeleton/skeleton.constants";

export interface IInspectorsFindingDetailsContext {
  onAcceptFinding?: (inspectorId: string, findingName: string) => void;
  onResumeFinding?: (
    inspectorId: string,
    findingName: string,
    trackingStatus: acceptancePeriodList
  ) => void;
}

export const InspectorsTableContext =
  createContext<IInspectorsFindingDetailsContext>(
    {} as IInspectorsFindingDetailsContext
  );

export default function Finding() {
  const {
    details,
    summary,
    loading,
    isLoadingFinding,
    affectedObjects,
    breadcrumb,
    icon,
    orgId,
    orgName,
    onStatusSelected,
    inspector,
    onAcceptFinding,
    onResumeFinding
  } = useFindingViewModel();

  const contextValues: IInspectorsFindingDetailsContext = useMemo(
    () => ({
      onAcceptFinding,
      onResumeFinding
    }),
    [onAcceptFinding, onResumeFinding]
  );

  return (
    <div className="finding">
      <Header icon={icon} title={breadcrumb} isLoading={!!orgId && loading} />
      <div className="finding__content">
        {isLoadingFinding ? (
          <div className="finding__loading">
            <img src={LOADING} alt="" />
          </div>
        ) : (
          <FindingSummary
            summary={summary}
            totalAffectedObjects={affectedObjects.length}
            onStatusSelected={onStatusSelected}
            loadingOrganizations={loading}
            orgName={orgName}
          />
        )}
        {isLoadingFinding ? (
          <div className="finding__loading">
            <img src={LOADING} alt="" />
          </div>
        ) : (
          <InspectorsTableContext.Provider value={contextValues}>
            <FindingDetails
              details={details}
              affectedObjects={affectedObjects}
              inspector={inspector}
            />
          </InspectorsTableContext.Provider>
        )}
      </div>
    </div>
  );
}
