import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useFindingsAPI from "../api/useFindingsAPI";
import {
  acceptFindingsRequestParams,
  IFinding,
  IInspector
} from "./finding.types";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { WARNING_CIRCLE } from "../constants/images";
import { URL_ORGANIZATIONS } from "../constants/appUrls";
import useInspectorsAPI from "../api/useInspectorsAPI";

export default function useFindingModel() {
  const { getFindingById, patchLastScanByTenantId } = useFindingsAPI();
  const { getInspectorById, getInspectorsByOrganizationId } =
    useInspectorsAPI();
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const getFinding = async (scanId: string, findingId: string) => {
    try {
      const finding: IFinding = await getFindingById(scanId, findingId);
      return finding;
    } catch (error: any) {
      console.log(
        `Error getting finding ${findingId} from scan ${scanId}. ${error.message}`
      );
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
      return null;
    }
  };

  const getInspector = async (inspectorId: string) => {
    try {
      const inspector: IInspector = await getInspectorById(inspectorId);
      return inspector;
    } catch (error: any) {
      console.log(`Error getting Inspector data. ${error.message}`);
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
      return null;
    }
  };

  const getInspectorCurrentStatus = async (
    orgId: string,
    inspectorId: string
  ): Promise<boolean> => {
    try {
      const response = await getInspectorsByOrganizationId(orgId, inspectorId);
      return response.isPaused;
    } catch (error: any) {
      console.log(`Error getting Inspector data. ${error.message}`);
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
      return false;
    }
  };

  const acceptFinding = async (
    tenantId: string,
    body: acceptFindingsRequestParams
  ): Promise<any> => {
    try {
      const response = await patchLastScanByTenantId(tenantId, body);
      return response;
    } catch (error: any) {
      console.log(`Error getting Inspector data. ${error.message}`);
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
      return false;
    }
  };

  return { getFinding, getInspector, getInspectorCurrentStatus, acceptFinding };
}
