import { acceptFindingRequestParams } from "../finding/finding.types";
import apiResponseFormatter from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useFindingsAPI() {
  const { getOptions, setMethod, setBody } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiUrl: string = process.env.REACT_APP_API_FINDINGS!;
  const apiVersion = "";
  const apiPrefix = `${apiUrl}${apiVersion}`;

  const getLastScanByOrganizationId = async (organizationId: string) => {
    setEndpoint(`${apiPrefix}/scans/last/${organizationId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const findings = await run(options);
    return apiResponseFormatter(findings);
  };

  const getFindingById = async (scanId: string, findingId: string) => {
    setEndpoint(`${apiPrefix}/findings/scan/${scanId}/by_id/${findingId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const finding = await run(options);
    return apiResponseFormatter(finding);
  };

  const patchFindingById = async (
    scanId: string,
    findingId: string,
    payload: any
  ) => {
    setEndpoint(`${apiPrefix}/findings/scan/${scanId}/by_id/${findingId}`);
    setMethod(API_METHODS.PATCH);
    setBody(payload);
    const options = getOptions();
    const finding = await run(options);
    return apiResponseFormatter(finding);
  };

  const patchLastScanByTenantId = async (
    tenantId: string,
    payload: acceptFindingRequestParams
  ) => {
    setEndpoint(`${apiPrefix}/findings/last/${tenantId}`);
    setMethod(API_METHODS.PATCH);
    setBody(payload);
    const options = getOptions();
    const finding = await run(options);
    return apiResponseFormatter(finding);
  };

  return {
    getLastScanByOrganizationId,
    patchLastScanByTenantId,
    getFindingById,
    patchFindingById
  };
}
