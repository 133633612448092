import React, { ReactNode, useId } from "react";
import "./toggle.scss";

type ToggleProps = {
  label?: string | ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  ariaLabel?: string;
};

export default function Toggle({
  label,
  disabled = false,
  checked = false,
  onChange,
  ariaLabel
}: ToggleProps) {
  const classRoot = "toggle";
  const toggleId = useId();
  const labelId = `${toggleId}-label`;

  let className = classRoot;
  className += checked ? ` ${classRoot}--checked` : "";
  className += disabled ? ` ${classRoot}--disabled` : "";

  const handleChange = () => {
    if (!disabled && onChange) {
      onChange(!checked);
    }
  };

  return (
    <div className={`${classRoot}-container`}>
      <div
        className={className}
        onClick={handleChange}
        role="switch"
        aria-checked={checked}
        tabIndex={disabled ? -1 : 0}
        aria-labelledby={label ? labelId : undefined}
        aria-label={!label ? ariaLabel : undefined}
        id={toggleId}
      >
        <div className={`${classRoot}__slider`} />
      </div>
    </div>
  );
}
