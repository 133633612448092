import React, { useContext, useMemo } from "react";
import { TableColumnProps } from "../../designSystem/table/tableTypes";
import Chip from "../../designSystem/chip/chip";
import { SHAPES } from "../../designSystem/chip/chip.constants";
import SKELETON_VARIANT from "../../designSystem/skeleton/skeleton.constants";
import {
  AcceptancePeriodValuesExtends,
  AcceptancePeriodValuesShort,
  FINDING_SEVERITY
} from "../../finding/finding.types";
import "../scan.scss";
import featureFlagsContext from "../../contexts/feature-flags.provider";

function getChipLabelName(
  value: keyof typeof AcceptancePeriodValuesShort
): string {
  return AcceptancePeriodValuesShort[value];
}

function FindingName({
  data: {
    original: { name, activity }
  }
}: any) {
  const { inspectAcceptedRisk } = useContext(featureFlagsContext);
  // TODO: this will come as a prop called TrackingStatus
  const chipLabelName = useMemo(() => {
    if (activity && activity.length > 0) {
      const { trackingStatus } = [...activity].pop();
      return trackingStatus === AcceptancePeriodValuesExtends.None
        ? ""
        : trackingStatus;
    }
    return "";
  }, [activity]);

  return (
    <div className="scan__table__finding__name">
      {inspectAcceptedRisk && chipLabelName && (
        <div className="scan__table__finding__name__tracking_status">
          <Chip
            label={getChipLabelName(chipLabelName)}
            shape={SHAPES.OVAL}
            status="informational"
          />
        </div>
      )}
      <p>{name}</p>
    </div>
  );
}

const findingsTableFactoryColumns: TableColumnProps[] = [
  {
    accessorKey: "id",
    enableGlobalFilter: false,
    hide: true
  },
  {
    accessorKey: "severity",
    skeleton: SKELETON_VARIANT.text,
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: "equalsString",
    header: "Severity",
    sortDescFirst: true,
    sortingFn: (firstRow: any, secondRow: any, columnId: any): number => {
      const firstSeverity = parseInt(
        FINDING_SEVERITY[firstRow.getValue(columnId)],
        10
      );
      const secondSeverity = parseInt(
        FINDING_SEVERITY[secondRow.getValue(columnId)],
        10
      );

      return firstSeverity - secondSeverity;
    },
    cell: (props: any) => {
      const label = props.getValue();
      return (
        <div className="scan__table__severity">
          <Chip shape={SHAPES.RECTANGLE} status={label.toLowerCase()} />
          {label}
        </div>
      );
    }
  },
  {
    accessorKey: "name",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: "Finding",
    sortDescFirst: false,
    sortingFn: "text",
    cell: ({ row: original }) => <FindingName data={original} />
  },
  {
    accessorKey: "affected",
    skeleton: SKELETON_VARIANT.shortText,
    enableSorting: true,
    header: "Affected",
    sortingFn: "basic"
  },
  {
    accessorKey: "firstSeen",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: "First seen",
    sortingFn: "basic"
  },
  {
    accessorKey: "status",
    skeleton: SKELETON_VARIANT.chip,
    header: "Status",
    cell: (props: any) => {
      const label = props.getValue();
      return (
        <div className="scan__table__status">
          <Chip
            label={label}
            shape={SHAPES.OVAL}
            status={label.toLowerCase()}
          />
        </div>
      );
    }
  }
];

export default findingsTableFactoryColumns;
