export default {
  "Accept & Remove from Scans": "Accept & Remove from Scans",
  Pause: "Pause",
  Resume: "Resume",
  "Accept Finding": "Accept Finding",
  "Resume Finding": "Resume Finding",
  "Accept Risk": "Accept Risk",
  "Accept until changed": "Accept until changed",
  "Accept permanently": "Accept permanently"
};
