import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useMemo } from "react";

export type IfeatureFlagContext = {
  inspectAcceptedRisk: boolean;
};

const featureFlagsContext = createContext<IfeatureFlagContext>(
  {} as IfeatureFlagContext
);

export function FeatureFlagsProvider({ children }: any) {
  const { inspectAcceptedRisk } = useFlags();
  const flags = useMemo(() => ({ inspectAcceptedRisk }), [inspectAcceptedRisk]);
  console.log({ flags });
  return (
    <featureFlagsContext.Provider value={flags}>
      {children}
    </featureFlagsContext.Provider>
  );
}

export default featureFlagsContext;
