/* eslint-disable no-shadow */
// Values defined in https://soteriallc.atlassian.net/wiki/spaces/DEV/pages/2339340289/Finding+and+Returned+Values+Statuses
import { IAnyPropertyNameAndAnyValue } from "../constants/common.types";

export enum FINDING_EVENTS {
  statusSelected = "statusSelected" // Sent by Finding when a status is selected from the Status History list and handled by FindingDetails
}

export enum FINDING_STATUS {
  new = "new",
  modified = "modified",
  unchanged = "unchanged",
  remediated = "remediated",
  none = ""
}

export enum FINDING_SEVERITY {
  "Informational",
  "Low",
  "Medium",
  "High",
  "Critical"
}

export type AffectedObject = {
  name: string;
  status: FINDING_STATUS;
};

export type AffectedObjectUI = {
  name: string;
  status?: FINDING_STATUS;
};

type References = {
  text: string;
  url: string;
};

type licenseLevel = {
  label: string;
  value: string;
};

type Attribute = {
  licenseLevel: licenseLevel;
};

export type Frameworks = {
  id: string;
  name: string;
  version: string;
  categoryLabel: string;
  category: string;
  controlLabel: string;
  control: string;
  controlName: string;
  controlDescription: string;
  attributes: Attribute;
};

export type FindingSummary = {
  firstSeen: number;
  lastSeen: number;
};

export type FindingHistory = {
  status: FINDING_STATUS;
  date: number;
  affectedObjects: AffectedObject[];
};

type FindingMetadata = {
  inspectorVersion: string;
  inspectorId: string;
  author: string;
  organization: string;
  creationDate: string;
};

export type IInspector = {
  adminCenter: string;
  CISControlNumber: string;
  createdAt: number;
  defaultValue: string;
  dependsOn: any;
  description: string;
  expectedValue: string;
  expireAt: number;
  findingName: string;
  frameworks: Frameworks[];
  id: string;
  impact: FINDING_SEVERITY;
  inspectorName: string;
  isActive: boolean;
  licenseRequirement: string;
  MITREATTACK: string;
  metadata: FindingMetadata;
  NISTControls: string;
  powerShellModule: string;
  powerShellRemediation: string;
  references: References[];
  remediation: string;
  returnedValue: string;
  riskRating: string;
  startedAt: number;
  status: FINDING_STATUS;
  SubscriptionLevel: any;
};

export type IFinding = {
  id: string;
  impact: FINDING_SEVERITY;
  hasFinding: boolean;
  inspectorID: string;
  inspectorName: string;
  reportId: string;
  status: FINDING_STATUS;
  summary: FindingSummary;
  findingName: string;
  history: any;
  scanCreatedAt: number;
  affectedObjects: AffectedObject[];
  isPaused: boolean;
  scanId?: string;
  trackingStatus: string;
  activity?: any[];
};

export type FindingUI = {
  id: string;
  severity: FINDING_SEVERITY;
  name: string;
  affected: number;
  firstSeen: string;
  status: FINDING_STATUS;
  isPaused: boolean;
  finding: IFinding;
  scanId?: string;
  activity?: any[];
};

export type FindingSummaryDatesUI = {
  firstSeen: string;
  lastSeen: string;
};

export type FindingHistoryUI = {
  status: FINDING_STATUS;
  date: string;
  affectedObjects: AffectedObject[];
  active: boolean;
};

export type FindingSummaryUI = {
  severity: FINDING_SEVERITY;
  history: FindingHistoryUI[];
  dates: FindingSummaryDatesUI;
};

export const AcceptancePeriodValues = {
  "Accept until changed": "AcceptedRisk",
  "Accept permanently": "IgnorePermanently"
} as const;

export const AcceptancePeriodValuesShort = {
  AcceptedRisk: "Accepted",
  IgnorePermanently: "Accepted Permanently",
  None: ""
} as const;

export const AcceptancePeriodValuesExtends = {
  None: "None"
} as const;

export type AcceptancePeriodType = typeof AcceptancePeriodValues;
export type AcceptancePeriodKey = keyof AcceptancePeriodType;

export type acceptancePeriodList =
  | AcceptancePeriodType
  | typeof AcceptancePeriodValuesExtends;

export const acceptancePeriodListOptions = {
  ...AcceptancePeriodValues,
  ...AcceptancePeriodValuesExtends
};

export type FindingDetailsUI = {
  findingName: string;
  description: string;
  remediation: string;
  references: References[];
  status: FINDING_STATUS;
  frameworks: IAnyPropertyNameAndAnyValue;
  affectedObjects: AffectedObject[];
  inspectorID: string;
  trackingStatus: acceptancePeriodList;
};

export type IFindingsList = { [key: string]: FindingUI };

export type acceptFindingRequestParams = {
  FindingName: string;
  InspectorId: string;
  TrackingStatus: string;
  Note: string;
};

export type AcceptancePeriodOption = {
  value: AcceptancePeriodKey;
  id: AcceptancePeriodKey;
};

export type acceptFindingFormValues = {
  acceptedRisk?: acceptancePeriodList;
  notes: string;
};

export interface acceptFindingsRequestParams {
  FindingName: string;
  InspectorId: string;
  TrackingStatus: string;
  Note: string;
}
