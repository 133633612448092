import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import useFindingModel from "./finding.model";
import {
  AffectedObject,
  IFinding,
  FindingDetailsUI,
  FindingHistory,
  FindingHistoryUI,
  FindingSummaryUI,
  FINDING_EVENTS,
  FINDING_SEVERITY,
  IInspector,
  acceptFindingFormValues,
  acceptFindingRequestParams,
  acceptFindingsRequestParams,
  acceptancePeriodList,
  acceptancePeriodListOptions
} from "./finding.types";
import FINDING_CONSTANTS from "./finding.constants";
import Breadcrumb from "../designSystem/breadcrumb/breadcrumb";
import {
  URL_INSPECTORS,
  URL_ORGANIZATIONS,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_MSP,
  URL_INSPECTORS_MSP
} from "../constants/appUrls";
import Icon from "../designSystem/icon/icon";
import { ERROR, INFORMATION, SCAN, WARNING_CIRCLE } from "../constants/images";
import formatDate from "../utils/formatDate";
import OrganizationsContext from "../contexts/organizations.context";
import { buildUrl } from "../utils/string.utils";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { useAppSelector } from "../redux/hooks";
import AcceptFindingsForm, {
  ResumeFindingForm
} from "./components/AcceptFindingsForm";
import ModalContext from "../contexts/modal.context";
import { MAX_CHARS_NOTES } from "../constants/utils";

function groupByFrameworkName(framework: any) {
  return framework.reduce((acc: any, curr: any) => {
    const { name, version } = curr;
    const key = `${name} ${version}`;
    const { controlDescription } = curr;
    if (controlDescription?.trim().length > 0) {
      acc[key] = acc[key] || {
        name,
        version,
        data: []
      };
      acc[key].data.push(curr);
    }
    return acc;
  }, {});
}

function acceptFindingRequestParamsMapper(
  inspectorId: string,
  acceptedRisk: keyof acceptancePeriodList,
  notes: string,
  findingName: string
): acceptFindingRequestParams {
  return {
    InspectorId: inspectorId,
    TrackingStatus: acceptancePeriodListOptions[acceptedRisk],
    Note: notes,
    FindingName: findingName
  };
}

const ACCEPT_FINDING_CONST = {
  max_error: `Must be lower than ${MAX_CHARS_NOTES}`
};

export default function useFindingViewModel() {
  const { getFinding, getInspector, acceptFinding } = useFindingModel();
  const [summary, setSummary] = useState<FindingSummaryUI>({
    dates: {
      firstSeen: "",
      lastSeen: ""
    },
    history: [],
    severity: FINDING_SEVERITY.Informational
  });
  const [details, setDetails] = useState<FindingDetailsUI>(
    {} as FindingDetailsUI
  );

  const [affectedObjects, setAffectedObjects] = useState<AffectedObject[]>([]);
  const [isLoadingFinding, setIsLoadingFinding] = useState(true);
  const loadingRef = useRef(false);
  const [inspector, setInspector] = useState<IInspector | null>(null);

  const { showModal, setAsyncLoading, closeModal } = useContext(ModalContext);

  const {
    organizationName,
    tenant: { id: tenantId }
  } = useAppSelector((state: any) => state.session);

  const { organizationsList, loading, dataLoaded } =
    useContext(OrganizationsContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { orgId, scanId, findingId } = useParams();
  const navigate = useNavigate();

  const icon = useMemo(() => <Icon image={SCAN} alt="Finding" />, []);

  const breadcrumb = useMemo(() => {
    const scanUrl = orgId ? buildUrl(URL_SCAN_MSP, orgId) : URL_SCAN;
    const items = [
      {
        content: "Scan",
        link: scanUrl
      },
      {
        content: `${details?.findingName}`,
        menuItems: [
          {
            id: scanUrl,
            value: "Scan"
          },
          {
            id: orgId ? buildUrl(URL_USERS_MSP, orgId) : URL_USERS,
            value: "Users"
          },

          {
            id: orgId ? buildUrl(URL_INSPECTORS_MSP, orgId) : URL_INSPECTORS,
            value: "Inspectors"
          }
        ],
        isLoadingItem: isLoadingFinding
      }
    ];
    return (
      <Breadcrumb
        items={
          orgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[orgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [details?.findingName, isLoadingFinding, orgId, organizationsList]);

  const orgName = useMemo(
    () => (orgId ? organizationsList[orgId!]?.name : organizationName),
    [orgId, organizationName, organizationsList]
  );

  const validationSchema = Yup.object().shape({
    acceptedRisk: Yup.string().required("Accepted risk is required"),
    notes: Yup.string().max(MAX_CHARS_NOTES, ACCEPT_FINDING_CONST.max_error)
  });

  const updateHistoryDate = useCallback(
    (history: FindingHistory[]) =>
      history.map((item) => ({
        status: item.status,
        affectedObjects: item.affectedObjects ? item.affectedObjects : [],
        date: formatDate(item.date),
        active: false
      })),
    []
  );

  const getHistory = useCallback(
    (finding: IFinding) => {
      const currentStatus: FindingHistoryUI = {
        status: finding.status,
        date: formatDate(finding.summary.lastSeen),
        affectedObjects: finding.affectedObjects || [],
        active: true
      };
      const history: FindingHistoryUI[] = [currentStatus];
      return finding.history
        ? history.concat(updateHistoryDate(finding.history))
        : history;
    },
    [updateHistoryDate]
  );

  const loadAffectedObjectsFromHistory = useCallback(
    (historyIndex: number) => {
      setAffectedObjects(summary.history[historyIndex].affectedObjects);
      setDetails((prevState: FindingDetailsUI) => ({
        ...prevState,
        status: summary.history[historyIndex].status
      }));

      const newHistory = summary.history.map((item, index) => ({
        ...item,
        active: index === historyIndex
      }));

      setSummary((prevState: FindingSummaryUI) => ({
        ...prevState,
        history: newHistory
      }));
    },
    [summary.history]
  );

  const onStatusSelected = useCallback(
    (index: number) => {
      loadAffectedObjectsFromHistory(index);
      const statusEvent = new CustomEvent(FINDING_EVENTS.statusSelected);
      window.dispatchEvent(statusEvent);
    },
    [loadAffectedObjectsFromHistory]
  );

  const loadFinding = useCallback(async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    const findingResponse: IFinding | null = await getFinding(
      scanId!,
      findingId!
    );
    const inspectorResponse: IInspector | null = await getInspector(
      findingResponse?.inspectorID || ""
    );

    if (findingResponse && inspectorResponse) {
      setInspector(inspectorResponse);
      setDetails({
        findingName: findingResponse.findingName,
        status: findingResponse.status,
        affectedObjects: findingResponse.affectedObjects || [],
        description: inspectorResponse?.description,
        remediation: inspectorResponse?.remediation,
        references: inspectorResponse?.references,
        frameworks: groupByFrameworkName(inspectorResponse?.frameworks),
        inspectorID: findingResponse.inspectorID,
        trackingStatus:
          findingResponse?.trackingStatus as keyof acceptancePeriodList
      });
      setSummary({
        severity: findingResponse.impact,
        history: getHistory(findingResponse),
        dates: {
          firstSeen: formatDate(findingResponse.summary.firstSeen),
          lastSeen: formatDate(findingResponse.summary.lastSeen)
        }
      });

      setAffectedObjects(findingResponse.affectedObjects || []);
    }
    loadingRef.current = false;

    setIsLoadingFinding(false);
  }, [
    findingId,
    getFinding,
    getHistory,
    getInspector,
    scanId
    // inspectorCurrentStatus,
    // orgId,
    // organizationId
  ]);

  useEffect(() => {
    loadFinding();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgId && dataLoaded && !organizationsList[orgId!]) {
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  const onSubmitAccept = useCallback(
    async (acceptFindingsParams: acceptFindingsRequestParams) => {
      try {
        setAsyncLoading(true);

        await acceptFinding(tenantId || "", acceptFindingsParams);
        closeModal();
        showSnackbar({
          text: `Finding successfully accepted`,
          type: MESSAGE.info,
          icon: INFORMATION
        });
        setIsLoadingFinding(true);
        loadFinding();
      } catch (error: any) {
        console.error(`Error accepting a Finding`);
        setAsyncLoading(false);
        showSnackbar({
          text: `Error accepting a Finding`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [
      setAsyncLoading,
      acceptFinding,
      tenantId,
      closeModal,
      showSnackbar,
      loadFinding
    ]
  );

  const showResumeFindingModal = useCallback(
    (
      inspectorId: string,
      findingName: string,
      trackingStatus: acceptancePeriodList
    ) => {
      showModal({
        title: FINDING_CONSTANTS["Resume Finding"],
        actionText: FINDING_CONSTANTS["Resume"],
        isForm: true,
        content: (
          <Formik
            initialValues={{
              acceptedRisk: acceptancePeriodListOptions.None,
              notes: ""
            }}
            onSubmit={({ acceptedRisk, notes }) => {
              onSubmitAccept(
                acceptFindingRequestParamsMapper(
                  inspectorId,
                  acceptedRisk as keyof acceptancePeriodList,
                  notes,
                  findingName
                )
              );
            }}
            // validationSchema={validationSchema}
            // validateOnMount
          >
            {(formik) => (
              <ResumeFindingForm
                formik={formik}
                findingName={findingName}
                trackingStatus={trackingStatus}
              />
            )}
          </Formik>
        ),
        formId: "resumeFindingForm"
      });
    },
    [onSubmitAccept, showModal]
  );

  const showAcceptFindingModal = useCallback(
    (inspectorId: string, findingName: string) => {
      showModal({
        title: FINDING_CONSTANTS["Accept Finding"],
        actionText: FINDING_CONSTANTS["Accept Risk"],
        isForm: true,
        content: (
          <Formik
            initialValues={{
              notes: ""
            }}
            onSubmit={({ acceptedRisk, notes }: acceptFindingFormValues) => {
              onSubmitAccept(
                acceptFindingRequestParamsMapper(
                  inspectorId,
                  acceptedRisk as keyof acceptancePeriodList,
                  notes,
                  findingName
                )
              );
            }}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => (
              <AcceptFindingsForm formik={formik} findingName={findingName} />
            )}
          </Formik>
        ),
        formId: "acceptFindingsForm"
      });
    },
    [onSubmitAccept, showModal, validationSchema]
  );

  const onAcceptFinding = useCallback(
    (inspectorId: string, findingName: string) =>
      showAcceptFindingModal(inspectorId, findingName),
    [showAcceptFindingModal]
  );

  const onResumeFinding = useCallback(
    (
      inspectorId: string,
      findingName: string,
      trackingStatus: acceptancePeriodList
    ) => showResumeFindingModal(inspectorId, findingName, trackingStatus),
    [showResumeFindingModal]
  );

  return {
    affectedObjects,
    onAcceptFinding,
    onResumeFinding,
    details,
    summary,
    loading,
    isLoadingFinding,
    breadcrumb,
    icon,
    orgId,
    orgName,
    inspector,
    onStatusSelected
  };
}
